import { ReactNode } from "react";
import { GlobalError, GlobalStatus } from "@dnb/eufemia";
import { useGetPortfolios } from "api/initial/useGetPortfolios";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";

interface PortfolioGuardProps {
  children: ReactNode;
}

export const PortfolioGuard = ({ children }: PortfolioGuardProps) => {
  const { data: portfolios = [], loading } = useGetPortfolios();
  if (loading) return <LoadingIndicator center />;
  if (portfolios.length === 0) {
    return <GlobalError status="403" title="Access Denied" lang="en-GB" />;
  }

  return <>{children}</>;
};
