import { gql, useQuery } from "@apollo/client";
import { getFetchPolicyOptions } from "api/utils";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";

export const useGetPortfolios = () => {
  const { linkedContact } = useKeycloak();
  const { selectedContactId } = useGetContractIdData();

  const { loading, error, data } = useQuery<ContactPortfoliosQuery>(
    CONTACT_PORTFOLIOS_QUERY,
    {
      variables: {
        contactId: selectedContactId || linkedContact,
      },
      ...getFetchPolicyOptions(
        `useGetSelectedContactPortfolios.${selectedContactId || linkedContact}}`
      ),
    }
  );

  return {
    loading,
    error,
    data: data && [
      ...(data?.contact?.portfolios || []),
      ...(
        data?.contact?.representees?.map(
          (representee) => representee.portfolios
        ) || []
      ).flat(),
    ],
  };
};

const CONTACT_PORTFOLIOS_QUERY = gql`
  query GetContactInfo($contactId: Long) {
    contact(id: $contactId) {
      id
      contactId
      representees(onlyDirectRepresentees: false) {
        id
        contactId
        portfolios {
          id
          shortName
          name
        }
      }
      portfolios {
        id
        shortName
        name
      }
    }
  }
`;

export interface ContactPortfoliosQuery {
  contact?: {
    id: number;
    representees: Representee[];
    portfolios: Portfolio[];
  };
}

export interface Portfolio {
  id: number;
  name: string;
  shortName: string;
}
interface Representee {
  id: number;
  contactId: number;
  portfolios: Portfolio[];
}
