//import { ReactComponent as Spinner } from "assets/spinner.svg";
import { ProgressIndicator } from "@dnb/eufemia";
import { Center } from "../Center/Center";

interface LoadingIndicatorProps {
  center?: boolean;
}

export const LoadingIndicator = ({ center = false }: LoadingIndicatorProps) => {
  //const SpinnerNode = (
  //  <Spinner className="w-16 h-16 text-gray-200 animate-spin fill-finanscoSecondary" />
  //);
  const SpinnerNode = <ProgressIndicator size="large" />;
  return center ? <Center>{SpinnerNode}</Center> : SpinnerNode;
};
