import { Suspense, lazy, useState } from "react";
import { Logo, Button, GlobalError, Autocomplete, Drawer } from "@dnb/eufemia";
import {
  log_out as LogOutIcon,
  hamburger as HamburgerIcon,
} from "@dnb/eufemia/icons";
import "@dnb/eufemia/style";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetPortfolios } from "api/initial/useGetPortfolios";
import classNames from "classnames";
import { LoadingIndicator, PortfolioGuard, TranslationText } from "components";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import {
  TOTAL_INVESTMENTS_OPTION_ID,
  useGetPortfolioOptions,
} from "hooks/useGetPortfolioOptions";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { NavTabRoutes } from "layouts/NavTabLayout/NavTab/NavTabRoutes";
import { NavTabPath } from "layouts/NavTabLayout/NavTab/types";
import { useNavigateToPortfolioTab } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/useNavigateToPortfolioTab";
import { useRedirectIfOnlyOnePortfolio } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/useRedirectIfOnlyOnePortfolio";
import { PortfolioNavigationHeaderLayout } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeaderLayout";
import {
  Navigate,
  NavLink,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { keycloakService } from "services/keycloakService";
import { NotFoundView } from "views/notFoundView/notFoundView";
import { authUserMainRoutes } from "../authUser/routes";
import { PortfolioRoutes } from "./portfolio/routes";

const Analysis = lazy(() =>
  import("./analysis").then((module) => ({ default: module.AnalysisPage }))
);
const PortfolioAnalysis = lazy(() =>
  import("./portfolio/analysis").then((module) => ({
    default: module.AnalysisPage,
  }))
);

const PrimeFinance = lazy(() =>
  import("./primefinance").then((module) => ({
    default: module.PrimeFinancePage,
  }))
);
const PortfolioPrimeFinance = lazy(() =>
  import("./portfolio/primefinance").then((module) => ({
    default: module.PrimeFinancePage,
  }))
);

const PortfolioTransactions = lazy(() =>
  import("./portfolio/transactions").then((module) => ({
    default: module.TransactionsPage,
  }))
);
const PortfolioHoldings = lazy(() =>
  import("./portfolio/holdings").then((module) => ({
    default: module.HoldingsView,
  }))
);

const Overview = lazy(() =>
  import("./overview").then((module) => ({ default: module.OverviewPage }))
);
const PortfolioOverview = lazy(() =>
  import("./portfolio/overview").then((module) => ({
    default: module.OverviewPage,
  }))
);

const Holdings = lazy(() =>
  import("./holdings").then((module) => ({ default: module.HoldingsPage }))
);
const Holding = lazy(() =>
  import("./holdings/[holdingId]").then((module) => ({
    default: module.HoldingPage,
  }))
);
const Transactions = lazy(() =>
  import("./transactions").then((module) => ({
    default: module.TransactionsPage,
  }))
);
const TransactionDetails = lazy(() =>
  import("./transactions/[transactionId]").then((module) => ({
    default: module.TransactionDetailsPage,
  }))
);
const Orders = lazy(() =>
  import("./orders").then((module) => ({ default: module.OrdersPage }))
);
const OrderDetails = lazy(() =>
  import("./orders/[orderId]").then((module) => ({
    default: module.OrderDetailsPage,
  }))
);
const Documents = lazy(() =>
  import("./documents").then((module) => ({ default: module.DocumentsPage }))
);
const Contact = lazy(() =>
  import("./contact").then((module) => ({ default: module.ContactPage }))
);
const Trading = lazy(() =>
  import("./trading").then((module) => ({ default: module.TradingPage }))
);

export const mainTabRoutes: NavTabPath[] = [
  {
    path: "overview",
    tabLabel: <TranslationText translationKey="navTab.tabs.overview" />,
    tabComponent: (
      <PortfolioGuard>
        <Overview />
      </PortfolioGuard>
    ),
    element: null,
  },
  {
    path: "holdings",
    tabLabel: <TranslationText translationKey="navTab.tabs.holdings" />,
    tabComponent: (
      <PortfolioGuard>
        <Holdings />
      </PortfolioGuard>
    ),
    element: null,
  },
  {
    path: "transactions",
    tabLabel: <TranslationText translationKey="navTab.tabs.transactions" />,
    tabComponent: (
      <PortfolioGuard>
        <Transactions />
      </PortfolioGuard>
    ),
    element: null,
  },
  {
    path: "orders",
    tabLabel: <TranslationText translationKey="navTab.tabs.orders" />,
    tabComponent: (
      <PortfolioGuard>
        <Orders />
      </PortfolioGuard>
    ),
    element: null,
  },
  {
    path: "documents",
    tabLabel: <TranslationText translationKey="navTab.tabs.documents" />,
    tabComponent: <Documents />,
    element: null,
  },
  {
    path: "trading",
    tabLabel: <TranslationText translationKey="navTab.tabs.trading" />,
    tabComponent: (
      <PortfolioGuard>
        <Trading />
      </PortfolioGuard>
    ),
    element: null,
  },
  {
    path: "contact",
    tabLabel: <TranslationText translationKey="navTab.tabs.contact" />,
    tabComponent: <Contact />,
    element: null,
  },
];

const linkedContactMainRoutes = [
  {
    path: "",
    element: <Navigate to="overview" replace />,
  },
  {
    path: "",
    element: <PortfolioNavigationHeaderLayout />,
    children: [
      {
        path: "*",
        element: <NavTabRoutes routes={mainTabRoutes} />,
      },
    ],
  },
  {
    path: "holdings/:holdingId",
    element: <Holding />,
  },
  {
    path: "transactions/:transactionId",
    element: <TransactionDetails />,
  },
  {
    path: "orders/:orderId",
    element: <OrderDetails />,
  },
];

export const userWithLinkedContactRoutes = [
  {
    path: "",
    element: <div></div>,
    children: [
      ...linkedContactMainRoutes,
      {
        path: "portfolio/:portfolioId/*",
        element: <PortfolioRoutes />,
      },
      ...authUserMainRoutes,
      {
        path: "*",
        element: <NotFoundView />,
      },
    ],
  },
];

export const UserWithLinkedContactRoutes = () => {
  const { data } = useGetContactInfo();
  const isCustody = !!data?.isCustody;
  return (
    <Routes>
      <Route
        path=""
        element={
          <Navigate to={isCustody ? "overview" : "primefinance"} replace />
        }
      />
      <Route path="" element={<MainLayout />}>
        <Route path="" element={<NavBar />}>
          <Route
            path="overview"
            element={
              isCustody ? (
                <PortfolioGuard>
                  <PortfolioOverview />
                </PortfolioGuard>
              ) : (
                <Navigate to={"/"} replace />
              )
            }
          />
          <Route
            path="primefinance"
            element={
              <PortfolioGuard>
                <PrimeFinance />
              </PortfolioGuard>
            }
          />
          <Route
            path="transactions"
            element={
              <PortfolioGuard>
                <Transactions />
              </PortfolioGuard>
            }
          />
          <Route
            path="holdings"
            element={
              <PortfolioGuard>
                <Holdings />
              </PortfolioGuard>
            }
          />
          <Route path="contact" element={<Contact />} />
          <Route
            path="trading"
            element={
              <PortfolioGuard>
                <Trading />
              </PortfolioGuard>
            }
          />
          <Route path="*" element={<GlobalError status="404" />} />
        </Route>
        <Route path="portfolio/:portfolioId/*">
          <Route
            path=""
            element={
              <Navigate to={isCustody ? "overview" : "primefinance"} replace />
            }
          />
          <Route path="" element={<NavBar />}>
            <Route
              path="primefinance"
              element={
                <PortfolioGuard>
                  <PortfolioPrimeFinance />
                </PortfolioGuard>
              }
            />
            <Route
              path="overview"
              element={
                isCustody ? (
                  <PortfolioGuard>
                    <PortfolioOverview />
                  </PortfolioGuard>
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            />
            <Route
              path="holdings"
              element={
                <PortfolioGuard>
                  <PortfolioHoldings />
                </PortfolioGuard>
              }
            />
            <Route
              path="transactions"
              element={
                <PortfolioGuard>
                  <PortfolioTransactions />
                </PortfolioGuard>
              }
            />
            <Route
              path="trading"
              element={
                <PortfolioGuard>
                  <Trading />
                </PortfolioGuard>
              }
            />
            <Route
              path="contact"
              element={
                <PortfolioGuard>
                  <Contact />
                </PortfolioGuard>
              }
            />
            <Route path="*" element={<GlobalError status="404" />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

interface PortfolioSelectorProps {
  onchange?: () => void;
}
const PortfolioSelector = ({ onchange }: PortfolioSelectorProps) => {
  const portfolioOptions = useGetPortfolioOptions();
  const { data: portfolios = [], loading: isLoading } = useGetPortfolios();
  const { portfolioId } = useParams();
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  useRedirectIfOnlyOnePortfolio();
  const onPortfolioChange = (urlPrefix: string) => {
    onchange && onchange();
    navigateToPortfolioTab(urlPrefix);
  };
  const currentPortfolio = portfolioId
    ? parseInt(portfolioId, 10)
    : TOTAL_INVESTMENTS_OPTION_ID;

  const portfolioList = portfolioOptions.map((x) => {
    return {
      selected_key: x.id,
      selected_value: x.label,
      content: x.label,
      urlPrefix: x.urlPrefix,
    };
  });

  //redirect to root when portfolioId does not match available portfolios
  if (
    currentPortfolio !== TOTAL_INVESTMENTS_OPTION_ID &&
    !isLoading &&
    !portfolios.some((option) => option.id === currentPortfolio)
  ) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="flex-1">
      {portfolioOptions.length > 1 ? (
        <Autocomplete
          lang="en-GB"
          show_submit_button
          data={portfolioList}
          title={
            portfolioOptions.find(
              (portfolio) => portfolio.id === currentPortfolio
            )?.label
          }
          on_change={({ data }) => onPortfolioChange(data.urlPrefix)}
        />
      ) : portfolioOptions.length === 1 ? (
        <div className="flex items-center ml-3 h-10 text-xl sm:text-2xl font-bold text-gray-900">
          {portfolioOptions[0].label}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

const NavBar = () => {
  const { portfolioId } = useParams();
  const isXl = useMatchesBreakpoint("xl");
  const navigate = useNavigate();
  const { data } = useGetContactInfo();
  const isCustody = !!data?.isCustody;
  return (
    <>
      <nav className="fixed z-200 left-0 right-0 top-0 w-full h-20 overflow-x-hidden bg-white border-b shadow-md">
        <div className="h-full flex items-center">
          <div className="absolute left-0 flex pl-4 gap-4 items-center">
            <div className="flex items-center text-[3.2rem]">
              <Logo
                size="auto"
                className="cursor-pointer"
                onClick={() =>
                  navigate(portfolioId ? `/portfolio/${portfolioId}/` : "/")
                }
              />
            </div>
            {isXl && <PortfolioSelector />}
          </div>

          {isXl && <NavElements isCustody={isCustody} />}

          <div className="absolute right-0 h-full pr-6 flex items-center gap-6">
            {!isXl && <DrawerItem isCustody={isCustody} />}
            <Button
              onClick={() => keycloakService.onAuthLogout()}
              title={"Log out"}
              text="Log out"
              icon={<LogOutIcon />}
              variant="tertiary"
              icon_position="left"
            />
          </div>
        </div>
      </nav>
      <Suspense fallback={<LoadingIndicator center />}>
        <Outlet />
      </Suspense>
    </>
  );
};

interface DrawerItemProps {
  isCustody?: boolean;
}
const DrawerItem = ({ isCustody }: DrawerItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Drawer
      closeTitle="Close"
      openState={isOpen}
      containerPlacement="left"
      triggerAttributes={{ icon: <HamburgerIcon /> }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <div className="pb-4">
        <PortfolioSelector onchange={() => setIsOpen(false)} />
      </div>

      <NavElements
        isCustody={isCustody}
        close={() => setIsOpen((prev) => !prev)}
      />
      <div className="pt-4">
        <Button
          onClick={() => keycloakService.onAuthLogout()}
          title={"Log out"}
          text="Log out"
          icon={<LogOutIcon />}
          variant="tertiary"
          icon_position="left"
        />
      </div>
    </Drawer>
  );
};

interface NavElementsProps {
  isCustody?: boolean;
  close?: () => void;
}
const NavElements = ({ close, isCustody }: NavElementsProps) => {
  return (
    <div className="w-full h-full flex flex-col xl:flex-row xl:justify-center items-start xl:items-center gap-4">
      <NavElement
        disabled={!isCustody}
        onClick={() => close && close()}
        title="Overview"
        to="overview"
      />
      <NavElement
        disabled={isCustody}
        onClick={() => close && close()}
        title="Prime Finance"
        to="primefinance"
      />
      <NavElement
        onClick={() => close && close()}
        title="Transactions"
        to="transactions"
      />
      <NavElement
        onClick={() => close && close()}
        title="Holdings"
        to="holdings"
      />
      <NavElement
        disabled={isCustody}
        onClick={() => close && close()}
        title="Contact"
        to="contact"
      />
    </div>
  );
};

const MainLayout = () => {
  return (
    <div className="box-border h-[calc(100vh-0.5rem)] overflow-x-hidden">
      <div className="flex flex-col h-full">
        <div className="mt-20 h-full flex w-full">
          <div className="container py-4 mx-auto">
            <ErrorBoundary>
              <Suspense fallback={<LoadingIndicator center />}>
                <Outlet />
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

interface NavElementProps {
  title: string;
  to: string;
  onClick?: () => void;
  disabled?: boolean;
}
const NavElement = ({ title, to, onClick, disabled }: NavElementProps) => {
  if (disabled) return <></>;
  return (
    <NavLink
      title={title}
      onClick={(e) => {
        e.currentTarget.blur();
        onClick && onClick();
      }}
      to={to}
      className={({ isActive }) => {
        return classNames("dnb-anchor", {
          "dnb-anchor--no-underline": !isActive,
        });
      }}
    >
      {title}
    </NavLink>
  );
};
